import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { faCalendar, faSync } from '@fortawesome/free-solid-svg-icons';
import React from 'react';

import { metaTextColor } from '../design/css-variables';
import ArticleMetaDate from './article-meta-date';
import TagList from './tag-list';

const ArticleMetaContainer = styled.div`
  margin-top: 8px;
  color: ${metaTextColor};
  font-size: 1.4rem;
`;

type Props = {
  postDate: string;
  updateDate?: string | null;
  tags: readonly string[];
};

// 投稿日時、更新日時、タグなどの記事のメタ情報表示
const ArticleMeta: React.FC<Props> = ({ postDate, updateDate, tags }) => {
  console.log(updateDate);
  return (
    <ArticleMetaContainer>
      <div>
        <ArticleMetaDate icon={faCalendar} date={postDate} />
        {updateDate && <ArticleMetaDate icon={faSync} date={updateDate} />}
      </div>
      {tags && 0 < tags.length && (
        <div
          css={css`
            margin-top: 8px;
          `}
        >
          <TagList tags={tags} />
        </div>
      )}
    </ArticleMetaContainer>
  );
};

export default ArticleMeta;
