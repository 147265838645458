import styled from '@emotion/styled';
import { Link } from 'gatsby';
import React from 'react';

import { asideTextColor, borderColor, titleHoverColor } from '../design/css-variables';

const PaginationContainer = styled.nav`
  overflow: hidden;
  margin-bottom: 32px;
`;

const PageLinkBox = styled.div`
  border: solid 1px ${borderColor};
  padding: 8px 16px;
  a {
    color: ${asideTextColor};
    text-decoration: none;
    box-shadow: none;
  }
  a:hover {
    color: ${titleHoverColor};
  }
`;

export type PageLink = {
  title: string;
  to: string;
};

type Props = {
  prevPage: PageLink | null;
  nextPage: PageLink | null;
};

const Pagination: React.FC<Props> = ({ prevPage, nextPage }) => {
  return (
    <PaginationContainer>
      {prevPage && (
        <PageLinkBox style={{ float: 'left' }}>
          <Link to={prevPage.to} rel='prev'>
            &laquo; {prevPage.title}
          </Link>
        </PageLinkBox>
      )}
      {nextPage && (
        <PageLinkBox style={{ float: 'right' }}>
          <Link to={nextPage.to} rel='next'>
            {nextPage.title} &raquo;
          </Link>
        </PageLinkBox>
      )}
    </PaginationContainer>
  );
};

export default Pagination;
