import styled from '@emotion/styled';
import React from 'react';

import ArticleListItem from '../components/article-list-item';
import Pagination from '../components/pagination';
import { EdgeNode } from '../types/data';
import { isNotUndefined } from '../utils/filter';

export type Props = {
  posts: readonly EdgeNode[];
  baseUrl: string;
  numPages: number;
  currentPage: number;
};

// 記事一覧に表示するものは全てリンクに下線を付けない
const ArticleListContainer = styled.div`
  a {
    text-decoration: none;
    box-shadow: none;
  }
`;

const ArticleList: React.FC<Props> = ({ posts, baseUrl, numPages, currentPage }) => {
  const prevPage =
    currentPage === 1
      ? null
      : {
          title: '前のページへ',
          to: baseUrl + (currentPage - 1 === 1 ? '' : String(currentPage - 1)),
        };
  const nextPage =
    currentPage === numPages
      ? null
      : {
          title: '次のページへ',
          to: baseUrl + String(currentPage + 1),
        };

  return (
    <ArticleListContainer>
      {posts.map(node => {
        const frontmatter = node.frontmatter;
        const title = frontmatter?.title || '';
        const slug = node.fields?.slug;
        if (!slug) throw new Error('slug is mandatory.');
        const postDate = frontmatter?.date || '不明';
        const updateDate = frontmatter?.updatedAt;
        const summary = frontmatter?.description || node.excerpt || '';
        const eyecatchImage = frontmatter?.featuredimage?.childImageSharp?.fluid || frontmatter?.featuredSVG;
        const tags = frontmatter?.tags?.filter(isNotUndefined) || [];
        return (
          <ArticleListItem
            key={slug}
            title={title}
            slug={slug}
            postDate={postDate}
            updateDate={updateDate}
            summary={summary}
            eyecatchImage={eyecatchImage}
            tags={tags}
          />
        );
      })}
      <Pagination prevPage={prevPage} nextPage={nextPage} />
    </ArticleListContainer>
  );
};

export default ArticleList;
