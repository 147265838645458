import styled from '@emotion/styled';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';

import { linkColor, readmoreColor } from '../design/css-variables';
import ArticleMeta from './article-meta';

const Article = styled.article`
  margin-bottom: 64px;
  overflow: hidden;
  a {
    color: #333;
  }
`;

const EyeCatch = styled.div`
  /* マウスホバーで画像ズームしてもはみ出した部分は切り取る */
  overflow: hidden;

  img {
    width: 100%;
    max-width: 100%;
    object-fit: cover;
    border-radius: 5px;
    /* 画像の下に隙間ができないようにする */
    vertical-align: bottom;
    margin-bottom: 0;
  }

  /* FIXME: use variables */
  @media screen and (min-width: 540px) {
    float: left;
    width: 224px;
    margin-right: 32px;
    img {
      max-height: 224px;
    }
  }

  /* マウスホバーで画像を拡大 */
  img {
    /* Gatsby が上書きするため!importantで防止 */
    transition: all 300ms 0s ease-in-out !important;
  }
  a:hover & img {
    transform: scale(1.08, 1.08);
  }
`;

const Content = styled.div`
  margin-top: 24px;
  padding-bottom: 16px; /* readmore 表示の分 */
  position: relative; /* readmore を absolute 指定するので基準をここに */

  /* FIXME: use variables */
  @media screen and (min-width: 540px) {
    margin-top: 4px;
    overflow: hidden;
  }
`;

const Title = styled.div`
  font-size: 2.4rem;
  line-height: 1.2;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 0;

  a:hover {
    color: ${linkColor};
  }
`;

const Summary = styled.div`
  margin-top: 16px;
  font-size: 1.4rem;
`;

const ReadMore = styled.div`
  opacity: 0;
  position: absolute;
  right: 32px;
  bottom: 0;
  color: ${readmoreColor};
  transition: all 300ms 0s ease-in-out;

  a:hover & {
    opacity: 1;
    transform: translate(1em, 0);
  }
`;

type Props = {
  title: string;
  slug: string;
  postDate: string;
  summary: string;
  tags: string[];
  updateDate?: string | null;
  eyecatchImage?: GatsbyTypes.GatsbyImageSharpFluidFragment | string | null;
};

const ArticleListItem: React.FC<Props> = ({ title, slug, postDate, summary, tags, updateDate, eyecatchImage }) => {
  return (
    <Article>
      {eyecatchImage && (
        <Link to={slug}>
          <EyeCatch>
            {eyecatchImage && typeof eyecatchImage !== 'string' && <Img fluid={eyecatchImage} />}
            {typeof eyecatchImage === 'string' && (
              <img width='224' height='224' src={eyecatchImage} alt='アイキャッチ画像' />
            )}
          </EyeCatch>
        </Link>
      )}
      <Content>
        <Title>
          <Link to={slug}>{title}</Link>
        </Title>
        <ArticleMeta postDate={postDate} updateDate={updateDate} tags={tags} />
        <Link to={slug}>
          <Summary dangerouslySetInnerHTML={{ __html: summary }} />
          <ReadMore>➡︎</ReadMore>
        </Link>
      </Content>
    </Article>
  );
};

export default ArticleListItem;
